const fbEvent = (type, params) => {
  if (window.fbq) {
    window.fbq('track', type, params)
  }
}

export const inspectletTagSession = tag => {
  if (window.__insp) {
    window.__insp.push(['tagSession', tag])
  }
}

export const fbStartSignup = () => fbEvent('InitiateCheckout')
export const fbFinishSignup = () =>
  fbEvent('StartTrial', { value: 0.0, currency: 'USD' })
export const fbViewedPricing = () => fbEvent('Lead')

export const gtagReportSignupToTrialCompleteForAdwords = () => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-815712820/RxJ7CNLGuaUBELSU-4QD',
    })
  }
}

export const gtagReportStartedSignupForAdwords = () => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-815712820/savcCKuEsqUBELSU-4QD',
    })
  }
}

export const gtagTriedToSetUpCall = () => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-815712820/vOxTCIfMuaUBELSU-4QD',
    })
  }
}

export const gtagSentAContactMessage = () => {
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-815712820/LvAiCLbRmc4BELSU-4QD',
    })
    window.gtag('event', 'sent_contact_us')
  }
}

export const gtagEvent = (stepName, value = undefined) => {
  if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
    window.gtag('event', 'freemiumSignupStep', {
      event_category: 'freemiumSignupStep',
      event_label: stepName,
      event_action: stepName,
      value: value,
    })
  }
}
