import React from 'react'
import { navigate } from 'gatsby'
import { Container, LinearProgress, Typography } from '@material-ui/core'
import { SignupClient } from '../../../utils/signupClient'

import { gtagEvent, inspectletTagSession } from '../../../utils/analytics'
import { useMutation } from 'react-query'
import Layout from '../../../components/layout'
import useFunnelConfirmationParams from './_utils'

const client = new SignupClient()
const FakeLinearProgress = props => {
  const [value, setValue] = React.useState(1)
  React.useEffect(() => {
    const update = () => setValue(Math.pow(Math.pow(value, 19) * 100, 0.05))
    const id = setInterval(update, 150 + 2 * value)
    return () => clearInterval(id)
  }, [value])
  return (
    <>
      <LinearProgress color={'secondary'} variant="determinate" value={value} />
    </>
  )
}
interface ConfirmEmailProps {
  location: Location
}

const ConfirmEmailBody = (props: ConfirmEmailProps) => {
  const confirmationData = useFunnelConfirmationParams(props.location)
  const [
    confirm,
    confirmStatus,
  ] = useMutation((data: { funnelId: string; code: string }) =>
    client.confirmCode(data)
  )

  React.useEffect(() => {
    if (
      confirmationData.funnel !== undefined &&
      confirmationData.key !== undefined
    ) {
      gtagEvent('3_confirmed_email')
      confirm({
        funnelId: confirmationData.funnel,
        code: confirmationData.key,
      })
    }
  }, [confirmationData])

  React.useEffect(() => {
    if (confirmStatus.isSuccess) {
      inspectletTagSession({
        signup: 'confirmed',
        confirmedWorkspace: confirmStatus.data.workspace,
      })
      setTimeout(
        () =>
          navigate(
            `/signup/confirm/launch/${props.location.search}&workspace=${confirmStatus.data.workspace}`
          ),
        2000
      )
    }
  }, [confirmStatus.isSuccess])

  if (confirmStatus.isLoading) {
    return <FakeLinearProgress />
  }
  if (confirmStatus.isSuccess) {
    return <Typography> Email Confirmed! Lets get you started</Typography>
  }
  if (confirmStatus.isError) {
    return (
      <>
        <Typography color={'error'}>
          Oh No!. Something is wrong with that confirmation email. Perhaps you
          used it already ?
        </Typography>
        <Typography>
          Try to signup again to get a new confirmation code
        </Typography>
      </>
    )
  }
  return (
    <>
      <Typography>
        Thanks for confirming. We're just doing a quick check
      </Typography>
      <FakeLinearProgress />
    </>
  )
}

const ConfirmEmailPage: React.FunctionComponent<ConfirmEmailProps> = props => (
  <Layout pageTitle="Confirm Your Email" pageDescription={'Quick Email Check'}>
    <Container>
      <ConfirmEmailBody location={props.location} />
    </Container>
  </Layout>
)

export default ConfirmEmailPage
