import React from 'react'
export interface ConfirmationParams {
  key?: string
  funnel?: string
  workspace?: string
  plansetId?:string
}
function useFunnelConfirmationParams(location: Location) {
  const [confirmationData, setConfirmationData] = React.useState<
    ConfirmationParams
  >({})
  React.useEffect(() => {
    const hashes = location.search.slice(1).split('&')
    debugger;
    const params: ConfirmationParams = {}

    hashes.map(hash => {
      const [key, val] = hash.split('=')
      if (['key', 'funnel', 'workspace',"plansetId"].includes(key)) {
        //@ts-ignore
        params[key] = decodeURIComponent(val)
      }
    })
    setConfirmationData(params)
  }, [])
  return confirmationData
}

export function useClientPromise<T>(func: (data: T) => Promise<any>) {
  const [isError, setIsError] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [error, setError] = React.useState<{ reason: string }>()
  const [isSuccess, setIsSuccess] = React.useState<boolean>(false)
  const [result, setResult] = React.useState<any>()
  const call = React.useCallback(async (data: T) => {
    try {
      setIsLoading(true)
      const res = await func(data)
      setResult(res)
      setIsSuccess(true)
      return res
    } catch (err) {
      setIsError(true)
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }, [])
  const res = { result, isSuccess, isLoading, isError, error }
  return { call, res }
}
export default useFunnelConfirmationParams
